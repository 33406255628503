import { useQuery, gql } from '@apollo/client';

export const GET_MEMBERSHIP = gql`
  query getMembership {
    membershipSection {
      data {
        attributes {
          title
          description_en
          description_dr
          term_condition_title_dr
          term_condition_title_en
          term_condition_en
          term_condition_dr
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetMembership = () => {
  const { loading, error, data } = useQuery(GET_MEMBERSHIP, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    membership: data?.membershipSection?.data,
    loading,
    error
  };
};
