/* eslint-disable prettier/prettier */
import Layout from '../../components/layout/layout';
import { Helmet } from 'react-helmet';
import { useGetCalendars } from '../../hooks/calendar/useGetCalendars';
import Spinner from '../../components/shared/Spinner';
import 'react-slideshow-image/dist/styles.css';
import { Slide, SlideshowRef } from 'react-slideshow-image';
import Image from '../../components/shared/Image';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { useRef } from 'react';
function Calendar() {
  const slideRef = useRef<SlideshowRef>(null);

  const { error, loading, calanders } = useGetCalendars(-1);

  const goTo = (slide: number) => {
    slideRef?.current?.goTo(slide);
  };
  return (
    <Layout>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Calendar</title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>
        {!loading && (
          <div className="flex justify-center pt-20">
            <div className="grid grid-cols-1 lg:grid-cols-8 gap-x-3 w-full max-w-6xl">
              <div className="lg:col-span-2 hidden lg:flex flex-col h-full justify-center gap-y-2 divide-y">
                {calanders?.data?.map((calander: any, index: number) => {
                  return (
                    <div
                      key={calander.attributes.title}
                      className="py-2 w-full text-center bg-gray-200 rounded-md hover:bg-gray-300 cursor-pointer"
                      onClick={() => goTo(index)}>
                      {calander.attributes.title}
                    </div>
                  );
                })}
              </div>
              <div className="w-full lg:col-span-6 mx-auto  slide-container">
                <Slide
                  arrows={true}
                  autoplay={false}
                  ref={slideRef}
                  nextArrow={
                    <button className="w-10 h-10 rounded-full bg-[#1B8901] p-2 top-[45%] -right-3">
                      <ChevronRightIcon className="text-white"></ChevronRightIcon>
                    </button>
                  }
                  prevArrow={
                    <button className="w-10 h-10 absolute rounded-full bg-[#1B8901] p-2 -left-5 top-[45%]">
                      <ChevronLeftIcon className=" text-white"></ChevronLeftIcon>
                    </button>
                  }>
                  {calanders?.data?.map((calander: any, index: number) => (
                    <div key={index} className="">
                      <Image
                        className="w-full h-full object-fill border-none"
                        src={calander?.attributes?.image.data?.attributes?.url}
                      />
                    </div>
                  ))}
                </Slide>
              </div>
            </div>
          </div>
        )}
        {loading && (
          <div className="w-full h-screen flex text-center justify-center pt-[20%]">
            <Spinner className="w-6 h-6"></Spinner>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Calendar;
