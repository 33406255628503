import { useQuery, gql } from '@apollo/client';

export const GET_HOME_SECTION = gql`
  query getHomeSection {
    aboutSection {
      data {
        attributes {
          title
          description
          image1 {
            data {
              attributes {
                url
              }
            }
          }
          image2 {
            data {
              attributes {
                url
              }
            }
          }
          image3 {
            data {
              attributes {
                url
              }
            }
          }
          image4 {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
    homeSection {
      data {
        attributes {
          title
          description_en
          description_dr
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetHomeSection = () => {
  const { loading, error, data } = useQuery(GET_HOME_SECTION, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    about: data?.aboutSection?.data,
    hero: data?.homeSection?.data,
    loading,
    error
  };
};
