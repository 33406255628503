import { useQuery, gql } from '@apollo/client';

export const GET_EVENT = gql`
  query getEvent($id: ID!) {
    event(id: $id) {
      data {
        id
        attributes {
          title
          content
        }
      }
    }
  }
`;

export const useGetEventDetails = (id: number) => {
  const { loading, error, data } = useQuery(GET_EVENT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id
    }
  });
  return {
    event: data?.event?.data,
    loading,
    error
  };
};
