import { useQuery, gql } from '@apollo/client';

export const GET_PUBLICATION = gql`
  query getPublication {
    publicationSection {
      data {
        attributes {
          title
          description
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
    publications {
      data {
        id
        attributes {
          title
          file {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetPublications = () => {
  const { loading, error, data } = useQuery(GET_PUBLICATION, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    publications: data?.publications,
    publicationSection: data?.publicationSection?.data,
    loading,
    error
  };
};
