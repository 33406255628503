import { useQuery, gql } from '@apollo/client';

export const GET_BLOGS = gql`
  query getBlogs($limit: Int) {
    blogs(sort: "createdAt:desc", pagination: { limit: $limit }) {
      data {
        id
        attributes {
          title
          createdAt
          description
          content
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetBlogs = (limit: number) => {
  const { loading, error, data } = useQuery(GET_BLOGS, {
    fetchPolicy: 'cache-and-network',
    variables: { limit: limit }
  });
  return {
    blogs: data?.blogs,
    loading,
    error
  };
};
