import Layout from '../../components/layout/layout';
import Spinner from '../../components/shared/Spinner';
import { useParams } from 'react-router-dom';
import sanitizedData from '../../utill/getSanitize';
import { useGetEventDetails } from '../../hooks/event/useGetEventDetails';
import { Helmet } from 'react-helmet';
function EventDetails() {
  const { slug } = useParams();
  const { loading, event } = useGetEventDetails(Number(slug));

  return (
    <Layout>
      <div className="w-full min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{event?.attributes?.title || ''}</title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>
        <div className=" w-full bg-[#FAFAFF] min-h-screen py-28">
          {loading ? (
            <div className="h-screen w-full text-center">
              <Spinner className="h-10"></Spinner>
            </div>
          ) : (
            <div className="max-w-7xl mx-auto">
              <div className="w-full">
                <div>
                  <div className="w-full text-center text-5xl font-sora  capitalize font-semibold mt-12 lg:mt-28 leading-[70px] tracking-[2%] px-5 sm:px-10 md:px-5  ">
                    <h1 className="font-bold font-sora text-[40px] leading-[60px] py-2 text-[#4A4A4A] capitalize">
                      {event?.attributes?.title}
                    </h1>
                    <div className="flex justify-center">
                      <div className=" h-1 w-20 bg-[#FED369] rounded-sm"></div>
                    </div>
                    <div>
                      <p
                        className="font-nunito mt-5  font-normal text-2xl leading-[38px] text-[#575757] tracking-[0.5%] text-justify"
                        dangerouslySetInnerHTML={sanitizedData(event?.attributes?.content)}></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
export default EventDetails;
