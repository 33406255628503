import { useGetCommittes } from '../../hooks/committe/useGetCommittes';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
const Committee = () => {
  const { committes, loading } = useGetCommittes();
  const responsiveSettings = [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ];

  return (
    <div className="w-full font-nunito relative py-5 bg-white ">
      <div className="max-w-7xl mx-auto md:py-20 py-5 xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
        <div className="w-full text-center md:text-4xl sm:text-3xl text-2xl capitalize font-semibold leading-[70px] tracking-[2%] ">
          <span className="text-[#565656]">Our</span>{' '}
          <span className="text-[#1B8901]">Committees</span>
        </div>
        <div className="w-full mt-12">
          {!loading && (
            <Slide
              arrows={true}
              autoplay={false}
              slidesToScroll={1}
              slidesToShow={2}
              nextArrow={
                <button className=" w-10 h-10 rounded-full bg-[#1B8901] p-2 top-20">
                  <ChevronRightIcon className="text-white"></ChevronRightIcon>
                </button>
              }
              prevArrow={
                <button className="w-10 h-10 rounded-full bg-[#1B8901] p-2 top-20">
                  <ChevronLeftIcon className=" text-white"></ChevronLeftIcon>
                </button>
              }
              responsive={responsiveSettings}>
              {committes?.data?.map((committe: any) => {
                return (
                  <div key={committe?.id} className="px-4">
                    {committe?.attributes?.link !== null ? (
                      <a href={committe?.attributes?.link} target="_blank" rel="noreferrer">
                        <img
                          className="h-[200px] w-full object-cover rounded-lg hover:cursor-pointer"
                          alt="not found"
                          src={committe?.attributes?.logo?.data?.attributes?.url}></img>
                      </a>
                    ) : (
                      <img
                        className="h-[200px] w-full object-cover rounded-lg"
                        alt=""
                        src={committe?.attributes?.logo?.data?.attributes?.url}></img>
                    )}
                    <div className="w-full text-center mt-2 text-2xl font-nunito font-semibold">
                      {committe.attributes.name}
                    </div>
                  </div>
                );
              })}
            </Slide>
          )}
        </div>
      </div>
    </div>
  );
};

export default Committee;
