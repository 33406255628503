import PropTypes, { InferProps } from 'prop-types';
import pdfIcon from '../../assets/images/pdf-icon.svg';
import { motion } from 'framer-motion';

const downloadVariant = {
  hover: {
    scale: 1.2,
    transition: {
      yoyo: Infinity
    }
  }
};
function PublicationItem({ id, title, file }: InferProps<typeof PublicationItem.propTypes>) {
  return (
    <div className="rounded-xl w-full group bg-gradient-to-b p-[2px] from-[#FAFAFA] hover:from-white hover:cursor-pointer hover:shadow-[0_37px_75px_rgba(0,0,0,0.07)] to-transparent">
      <div className="w-full h-wfull bg-white rounded-lg  py-10">
        <div className="rounded-2xl w-20 h-20 mx-auto p-5 bg-[#E2050E]/[0.2] text-center">
          <img
            className=" inline-flex rounded-lg w-19 h-12 mb-8 mx-auto cursor-pointer"
            src={pdfIcon}
            alt=""
            id="pdfIcon"></img>
        </div>
        <div className=" font-sora text-[#4A4A4A] text-2xl leading-9 text-center mt-5">{title}</div>
        <div className="text-center font-poppins mt-4 flex justify-center gap-x-1 text-[#575757] group-hover:text-[#E2050E]">
          <motion.a href={file || ''} variants={downloadVariant} target="_blank" whileHover="hover">
            View
          </motion.a>
        </div>
      </div>
    </div>
  );
}

PublicationItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  file: PropTypes.string
};

export default PublicationItem;
