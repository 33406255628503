import Layout from '../../components/layout/layout';
import { Helmet } from 'react-helmet';
import { useGetEvent } from '../../hooks/event/useGetEvent';
import { motion } from 'framer-motion';
import EventItem from '../../hooks/event/EventItem';

function Events() {
  const today = new Date();
  const months = today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1;
  const days = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
  const current = today.getFullYear() + '-' + months + '-' + days;
  const { loading, event } = useGetEvent(current);

  return (
    <Layout>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Events</title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>

        <div className=" max-w-7xl mx-auto">
          <div className="pb-10 grid lg:grid-cols-3 md:grid-cols-3 pt-32 lg:pt-56 min-h-screen sm:grid-cols-2 grid-cols-1 gap-5 mt-8 lg:px-0 md:px-10 px-5">
            {event?.map((eventItem: any, index: number) => {
              return (
                <motion.div
                  key={index}
                  initial={{ x: -200, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ duration: index }}>
                  <EventItem id={eventItem.id} title={eventItem?.attributes?.title}></EventItem>
                </motion.div>
              );
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Events;
