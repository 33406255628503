import sanitizedData from '../../utill/getSanitize';
interface Props {
  imgUrl: string;
  name: string;
  title: string;
  description: any;
}

function BoardMemberItem({ imgUrl, name, title, description }: Props) {
  return (
    <div className="card">
      <div className="rounded-xl w-full group bg-gradient-to-b py-10 px-5 p-[2px] text-center border-2 border-[#FAFAFA] hover:border-white hover:from-white hover:cursor-pointer hover:shadow-[0_37px_75px_rgba(0,0,0,0.07)]">
        <div className="w-full h-wfull ">
          <div className="w-32 h-32 mx-auto">
            <img
              alt="Image Empty"
              className="w-28 h-28 border-2 rounded-full border-[#1B8901] object-cover"
              src={imgUrl}></img>
          </div>
          <div className="font-sora text-[#575757] font-bold text-xl">{name}</div>
          <div className=" font-nunito text-[#CACCD3] text-base">{title}</div>
          <div
            className="font-poppins text-justify px-5"
            dangerouslySetInnerHTML={sanitizedData(description)}></div>
        </div>
      </div>
    </div>
  );
}

export default BoardMemberItem;
