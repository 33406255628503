import PropTypes, { InferProps } from 'prop-types';
import { formatDate } from '../../utill/formatDate';
import { ROUTES } from '../../static/data';

const downloadVariant = {
  hover: {
    scale: 1.2,
    transition: {
      yoyo: Infinity
    }
  }
};
function BlogItem({ id, title, description, date, image }: InferProps<typeof BlogItem.propTypes>) {
  return (
    <article className="flex flex-col">
      <a href={ROUTES.BLOGS_ROUTE + '/' + id}>
        <img className="object-cover w-full p-0 h-52 rounded-xl" src={image + ''} />
      </a>
      <div className="flex flex-col flex-1 py-6">
        <a
          rel="noopener noreferrer"
          href={ROUTES.BLOGS_ROUTE + '/' + id}
          className="flex-1 py-2 text-lg font-semibold leading-snug uppercase hover:underline">
          {title} {id}
        </a>

        <h3 className=" text-base tracking-wider  dark:text-violet-400">{description}</h3>
        <div className="flex flex-wrap justify-between pt-3 space-x-2 text-xs dark:text-gray-400">
          <span>{formatDate(date + '')}</span>
          <span>
            <a href={ROUTES.BLOGS_ROUTE + '/' + id} className="text-gray hover:text-[#1B8901]">
              Read More ...
            </a>
          </span>
        </div>
      </div>
    </article>
  );
}

BlogItem.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string
};

export default BlogItem;
