import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import client from '../../services/apollo-client';
import './App.css';
import Landing from '../landing/Landing';
import Donate from '../../pages/donate/donate';
import Membership from '../../pages/membership/membership';
import BoardOfDirectory from '../../pages/about-us/board-of-directory';
import Services from '../../pages/services/services';
import ServiceDetails from '../../pages/services/service-details';
import Publication from '../../pages/publication/publication';
import AboutUs from '../../pages/about-us/about-us';
import NotFound from '../../pages/errors/not-found';
import { ROUTES } from '../../static/data';
import EventDetails from '../../pages/event/event-details';
import Events from '../../pages/event/events';
import Blogs from '../../pages/blogs/blogs';
import BlogDetails from '../../pages/blogs/blog-details';
import Calendar from '../../pages/calendar/calendar';
import PrayTime from '../../pages/PrayTime/PrayTime';

function App() {
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path={ROUTES.HOME_ROUTE} element={<Landing />} />
          <Route path={ROUTES.DONATE_ROUTE} element={<Donate></Donate>}></Route>
          <Route path={ROUTES.MEMBERSHIP_ROUTE} element={<Membership></Membership>}></Route>
          <Route path={ROUTES.PUBLICATION_ROUTE} element={<Publication></Publication>}></Route>
          <Route path={ROUTES.SERVICES_ROUTE} element={<Services></Services>}></Route>
          <Route path={ROUTES.EVENT_ROUTE} element={<Events></Events>}></Route>
          <Route path={ROUTES.BLOGS_ROUTE} element={<Blogs></Blogs>}></Route>
          <Route path={ROUTES.BLOG_ROUTE} element={<BlogDetails></BlogDetails>}></Route>
          <Route path={ROUTES.CALENDAR_ROUTE} element={<Calendar />} />
          <Route path={ROUTES.PRAY_TIME_ROUTE} element={<PrayTime />} />
          <Route
            path={ROUTES.SERVICE_DETAILS_ROUTE}
            element={<ServiceDetails></ServiceDetails>}></Route>
          <Route path={ROUTES.EVENT_DETAILS_ROUTE} element={<EventDetails></EventDetails>}></Route>
          <Route path={ROUTES.ABOUTUS_ROUTE} element={<AboutUs></AboutUs>}></Route>
          <Route path={ROUTES.NOT_FOUND_ROUTE} element={<NotFound></NotFound>}></Route>
          <Route
            path={ROUTES.BORAD_DIRECTORY_ROUTE}
            element={<BoardOfDirectory></BoardOfDirectory>}></Route>
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
