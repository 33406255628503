import Layout from '../../components/layout/layout';
import { useGetServices } from '../../hooks/service/useGetServices';
import Spinner from '../../components/shared/Spinner';
function Services() {
  const { error, loading, services } = useGetServices();
  return (
    <div>
      <Layout>
        {loading ? (
          <div className="h-screen pt-[20%]">
            <Spinner className="h-10"></Spinner>
          </div>
        ) : (
          <>
            <div>
              {services.data.map((service: any) => {
                return <div key={service.id}>{service.attributes.title}</div>;
              })}
            </div>
          </>
        )}
      </Layout>{' '}
    </div>
  );
}
export default Services;
