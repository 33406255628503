import Layout from '../../components/layout/layout';
import mermberShipBorder from '../../assets/images/membership-border.svg';
import Iframe from 'react-iframe';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useGetMembership } from '../../hooks/membership/useGetMembership';
import Spinner from '../../components/shared/Spinner';
import sanitizedData from '../../utill/getSanitize';
import { Helmet } from 'react-helmet';
const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;

function Membership() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const { error, loading, membership } = useGetMembership();

  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Membership</title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>

        {!loading && (
          <div className="w-full">
            <motion.article
              initial="hidden"
              animate="visible"
              exit={{ opacity: 0, transition: { duration: 1 } }}
              variants={{ visible: { transition: { staggerChildren: 0.3 } } }}>
              <div className="w-full min-h-screen bg-[#FAFAFF] lg:pt-40 pt-32 pb-4">
                <div className="max-w-7xl mx-auto flex flex-col-reverse lg:grid lg:grid-cols-2 grid-cols-1 xl:px-0 lg:px-20 xl:gap-x-28 lg:gap-x-16 lg:mt-16 mt-4 md:px-16 sm:px-12 px-5">
                  <div className="px-5">
                    <motion.h1
                      variants={{
                        hidden: { opacity: 0, y: -20 },
                        visible
                      }}
                      className="font-bold font-sora lg:text-[40px] md:text-[30px] text-[20px] lg:leading-[60px] md:leading-[50px] leading-8 py-2 text-[#4A4A4A] lg:mt-16 mt-4 text-4xl">
                      {membership?.attributes?.title}
                    </motion.h1>
                    <motion.p
                      variants={itemVariants}
                      className="font-nunito lg:mt-5 md:mt-3 text-justify  mt-1 font-normal md:text-2xl text-xl leading-[38px] text-[#575757] tracking-[0.5%] ">
                      {membership?.attributes?.description_en}
                    </motion.p>
                    <motion.p
                      variants={itemVariants}
                      className="lg:mt-5 md:mt-3 font-IranSanse text-right mt-1 font-normal md:text-2xl text-xl leading-[38px] text-[#575757] tracking-[0.5%] ">
                      {membership?.attributes?.description_dr}
                    </motion.p>
                  </div>
                  <motion.div
                    className="flex relative justify-center"
                    initial={false}
                    animate={
                      isLoaded && isInView
                        ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                        : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                    }
                    transition={{ duration: 1, delay: 1 }}
                    viewport={{ once: true }}
                    onViewportEnter={() => setIsInView(true)}>
                    <img className="hidden lg:block" src={mermberShipBorder}></img>

                    <img
                      className="relative lg:absolute rounded-lg lg:rounded-full lg:w-52 w-full lg:h-52 md:h-[500px] sm:h-[300px] h-[200px] mx-auto lg:top-[32%] xl:top-[30%] object-cover"
                      src={membership?.attributes?.image?.data?.attributes?.url}
                      alt="not found"
                      onLoad={() => setIsLoaded(true)}></img>

                    <div className="absolute hidden lg:block bg-[#DBEFFA] rounded-full w-28 h-28 left-10 top-8 object-cover shadow-[0_14px_23px_rgba(65,132,247,0.24)]"></div>

                    <div className=" bg-[#DBDEFA] hidden lg:block absolute rounded-full w-16 h-16 mx-auto right-16 top-[29%] object-cover shadow-[0_14px_23px_rgba(65,132,247,0.24)]"></div>

                    <div className=" bg-[#F6DBFA] hidden lg:block absolute rounded-full w-24 h-24 mx-auto bottom-7 left-24 object-cover shadow-[0_14px_23px_rgba(65,132,247,0.24)]"></div>

                    <div className="bg-[#FADBE2] hidden lg:block absolute rounded-full w-20 h-20 bottom-12 right-20 object-cover shadow-[0_14px_23px_rgba(65,132,247,0.24)]"></div>
                  </motion.div>
                </div>
              </div>
            </motion.article>
            <div className="bg-white pb-10">
              <div className="max-w-7xl mx-auto xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                <div className="px-5">
                  <h1 className="font-bold font-sora lg:text-[40px] md:text-[30px] text-[20px] lg:leading-[60px] md:leading-[50px] leading-8 py-2 text-[#4A4A4A]">
                    {membership?.attributes?.term_condition_title_en}
                  </h1>
                  <span
                    className="font-nunito list-inside list-disc text-justify list-disc-red lg:mt-5 md:mt-3  mt-1 font-normal md:text-2xl text-xl leading-[38px] text-[#575757] tracking-[0.5%]"
                    dangerouslySetInnerHTML={sanitizedData(
                      membership?.attributes?.term_condition_en
                    )}></span>
                </div>
              </div>
            </div>
            <div className="bg-[#FAFAFF] py-10">
              <div className="max-w-7xl mx-auto xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                <div className="px-5 text-right">
                  <h1 className="font-bold lg:text-[40px] font-IranSanse md:text-[30px] text-[20px] lg:leading-[60px] md:leading-[50px] leading-8 py-2 text-[#4A4A4A]">
                    {membership?.attributes?.term_condition_title_dr}
                  </h1>
                  <span
                    dir="rtl"
                    className="lg:mt-5 md:mt-3 font-IranSanse justify-center mt-1 font-normal md:text-2xl text-xl leading-[38px] text-[#575757] tracking-[0.5%]"
                    dangerouslySetInnerHTML={sanitizedData(
                      membership?.attributes?.term_condition_dr
                    )}></span>
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className=" max-w-7xl mx-auto lg:px-0 md:px-10 px-5">
                <div className="wild-appricot">
                  <Iframe
                    url=""
                    className="w-full h-[1100px] relative mt-12 font-sora"
                    src="https://acccf.wildapricot.org/widget/join-us"
                    id=""
                    display="block"
                    position="relative"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {loading && (
          <div className="w-full h-screen flex text-center justify-center pt-[20%]">
            <Spinner className="w-6 h-6"></Spinner>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Membership;
