import { useQuery, gql } from '@apollo/client';

export const GET_SERVICE = gql`
  query getService($id: ID!) {
    service(id: $id) {
      data {
        id
        attributes {
          title
          content
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetService = (id: number) => {
  const { loading, error, data } = useQuery(GET_SERVICE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id
    }
  });
  return {
    service: data?.service,
    loading,
    error
  };
};
