/* eslint-disable react/jsx-key */
import React from 'react';
import { useTable } from 'react-table';

type Props = {
  columns: any;
  data: any;
};

const Table: React.FC<Props> = (props) => {
  // Use the useTable hook to create a table instance
  const { columns, data } = props;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  // Return a JSX element to render the table
  return (
    <div className="overflow-hidden rounded-lg">
      <table
        {...getTableProps()}
        className="min-w-full border font-IranSanse border-gray-900 divide-y divide-gray-100">
        <thead className="bg-[#1B8901]">
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup?.getHeaderGroupProps()} className="py-2">
              {headerGroup.headers.map((column) => (
                <th
                  scope="col"
                  className="px-3 py-3 text-center border border-gray-900 text-white"
                  {...column?.getHeaderProps()}>
                  <div>{column?.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          className="divide-y divide-x font-poppins divide-gray-900 bg-white rounded-lg"
          {...getTableBodyProps()}>
          {rows?.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="py-3">
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="py-2 text-center border border-gray-900 text-gray-700">
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
