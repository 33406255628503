interface Props {
  position: any;
}

function BoardHistoryItem({ position }: Props) {
  return (
    <div className="card">
      <div className="w-full h-full py-5 px-5 bg-white">
        <div className="text-center text-[#848B9B] text-xl font-sora font-bold mb-5 mt-2">
          {position?.attributes?.title}
        </div>
        {position?.attributes?.board_positions?.data?.map((member: any, index: number) => {
          return (
            <div key={index} className="text-[#BBC1CA]">
              <div className=" flex justify-center gap-x-5">
                <div>
                  {member?.attributes?.startDate.split('-')[0]}
                  {member?.attributes?.endDate === null ||
                  new Date(member?.attributes?.endDate).getTime() >= new Date().getTime()
                    ? ' - Now'
                    : ' - ' + member?.attributes?.endDate.split('-')[0]}
                </div>
                <div>{member?.attributes?.borad_member?.data?.attributes?.name}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BoardHistoryItem;
