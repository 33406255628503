import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import InputField from '../shared/InputField';
import TextAreaField from '../shared/TextAreaField';
import Button from '../shared/Button';
import { useCreateContact } from '../../hooks/contact/useCreateContact';
import { toast } from 'react-toastify';
const ContactUS = () => {
  const createContact = useCreateContact();
  const schema = yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    subject: yup.string(),
    message: yup.string()
  });

  // validation
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(schema)
  });

  const sendMessage = async (data: any) => {
    try {
      const response = await createContact({
        variables: {
          data: data
        }
      });
      if (response) {
        toast.success('your message sent');
        reset();
      }
    } catch (e) {
      toast.error('occured error please try again');
    }
  };

  return (
    <div id="contact-us" className="w-full relative py-10">
      <div className="max-w-6xl mx-auto py-20">
        <div className=" xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
          <div className=" relative py-5">
            <h1 className="font-bold text-2xl font-sora leading-9 capitalize text-[#4A4A4A]">
              Let’s Stay In Touch
            </h1>
            <div className=" h-1 w-20 rounded-sm bg-[#1B8901]"></div>
            <div className="w-full text-left font-nunito mt-12 text-lg text-[#575757]">
              ACCCF is a non-profit charitable organization in Saskatoon, serving hundreds of Afghan
              Canadian families.
            </div>
            <form onSubmit={handleSubmit(sendMessage)} action="#" method="POST" id="create-frame">
              <div className="grid gird-rows-4 gap-5 mt-8">
                <div className="grid grid-cols-2 gap-5">
                  <InputField
                    name="name"
                    label=""
                    placeholder="First Name"
                    register={register}
                    errors={errors?.name}
                  />
                  <InputField
                    name="email"
                    label=""
                    placeholder="Email"
                    register={register}
                    errors={errors?.email}
                  />
                </div>
                <div className="">
                  <InputField
                    name="subject"
                    label=""
                    placeholder="Subject"
                    register={register}
                    errors={errors?.subject}
                  />
                </div>
                <div>
                  <TextAreaField
                    name="message"
                    label=""
                    placeholder="Message"
                    register={register}
                    errors={errors?.message}
                  />
                </div>
                <div className="mt-5">
                  <Button secondary={true} type="submit">
                    Send Message
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUS;
