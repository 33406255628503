import { useGetServices } from '../../hooks/service/useGetServices';
import logo from '../../assets/images/logo-white.svg';
import { ROUTES } from '../../static/data';
import { useGetFooter } from '../../hooks/footer/useGetFooter';
const Footer = () => {
  const { loading, services } = useGetServices();
  const { footerLoading, error, footer } = useGetFooter();
  return (
    <div className="w-full bg-[#0A0E31] md:pt-20 pt-10 pb-20">
      {!footerLoading && (
        <div className="max-w-7xl mx-auto mt-20">
          <div className="grid border-b border-b-white pb-28 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:px-5 md:px-10 px-5">
            <div className="sm:col-span-2 col-span-1 pt-10 md:pt-0">
              <img className="h-32" src={logo} alt="" />
              <p className=" font-nunito text-lg w-full text-white max-w-[400px] opacity-70 mt-4">
                {footer?.attributes?.address}
              </p>
              <div className="flex justif-left gap-7 mt-6">
                {footer?.attributes?.whatsappLink && (
                  <a
                    href={footer?.attributes?.whatsappLink}
                    target="_blank"
                    rel="noreferrer noopener">
                    <img
                      className="h-7 w-7 hover:cursor-pointer"
                      src="/assets/icons/whatsapp-icon.svg"
                      alt=""
                    />
                  </a>
                )}

                {footer?.attributes?.facebookLink && (
                  <a
                    href={footer?.attributes?.facebookLink}
                    rel="noreferrer noopener"
                    target="_blank">
                    <img
                      className="w-7 h-7 hover:cursor-pointer"
                      src="/assets/icons/facebook-icon.svg"
                      alt=""
                    />
                  </a>
                )}

                {footer?.attributes?.youtubeLink && (
                  <a
                    href={footer?.attributes?.youtubeLink}
                    target="_blank"
                    rel="noreferrer noopener">
                    <img
                      className="w-7 h-7 mt-[2px] hover:cursor-pointer"
                      src="/assets/icons/youtube-icon.svg"
                      alt=""
                    />
                  </a>
                )}
              </div>
            </div>
            <div className=" mt-10 md:mt-0">
              <h1 className=" font-poppins font-semibold text-xl text-white leading-9 mb-6">
                OUR SERVICES
              </h1>
              {!loading &&
                services?.data?.map((service: any) => {
                  return (
                    <div
                      key={service?.id}
                      className="text-[#FFFFFF] font-sora mt-4 font-normal text-base opacity-70 hover:opacity-100 hover:cursor-pointer">
                      <a href={`${ROUTES.SERVICES_ROUTE}/${service?.id}`}>
                        {service?.attributes?.title}
                      </a>
                    </div>
                  );
                })}
            </div>
            <div className="mt-10 md:mt-0">
              <h1 className=" font-poppins font-semibold text-xl text-white leading-9 mb-6">
                OUR CONTACTS
              </h1>
              <div>
                <div className="flex flex-start mt-1">
                  <p className="text-white opacity-80 font-sora text-base leading-5 font-normal">
                    {' '}
                    Tel: {footer?.attributes?.phone}
                  </p>
                </div>
              </div>
              <div className="mt-4">
                <div className="flex flex-start">
                  <p className="font-sora opacity-80 text-white leading-9">
                    {footer?.attributes?.email}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-20 text-center text-white opacity-70 font-normal px-5 font-sora text-base">
            © 2022 - Afghan Canadian Community Charitable Foundation - All rights reserved.
          </div>
        </div>
      )}
    </div>
  );
};
export default Footer;
