export enum ROUTES {
  HOME_ROUTE = '/',
  DONATE_ROUTE = '/donate',
  MEMBERSHIP_ROUTE = '/membership',
  PUBLICATION_ROUTE = '/publication',
  SERVICES_ROUTE = '/services',
  SERVICE_DETAILS_ROUTE = '/services/:slug',
  EVENT_DETAILS_ROUTE = '/events/:slug',
  ABOUTUS_ROUTE = '/about-us',
  BLOGS_ROUTE = '/blogs',
  BLOG_ROUTE = '/blogs/:id',
  EVENT_ROUTE = '/events',
  CALENDAR_ROUTE = '/calendar',
  PRAY_TIME_ROUTE = '/pray-time',
  BORAD_DIRECTORY_ROUTE = '/board-of-director',
  LOGIN_ROUTE = 'https://acccf.wildapricot.org/Sys/Login',
  NOT_FOUND_ROUTE = '*'
}

export interface Meta {
  title: string;
  description: string;
}
