import PropTypes, { InferProps } from 'prop-types';

function Image({ title, src, className }: InferProps<typeof Image.propTypes>) {
  return (
    <img
      src={
        src ||
        'https://img.freepik.com/free-vector/drawn-clothing-donation-concept-illustrated_23-2148833206.jpg?w=740&t=st=1665047222~exp=1665047822~hmac=f84b114890079a46e93dbae3a602bf84b6485a702a967201e0fef6d6eec98d94'
      }
      alt={title || ''}
      className={`${className} object-cover border-[20px] bg-white border-primary/[0.2] `}
    />
  );
}

Image.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string
};

export default Image;
