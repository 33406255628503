import Layout from '../../components/layout/layout';
import { useGetBlogs } from '../../hooks/blogs/useGetBlogs';
import Spinner from '../../components/shared/Spinner';
import { Helmet } from 'react-helmet';
import BlogItem from '../../components/blogs/blogItem';
function Blogs() {
  const { error, loading, blogs } = useGetBlogs(-1);
  return (
    <div>
      <Layout>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Blogs</title>
          </Helmet>
        </div>
        {loading ? (
          <div className="h-screen pt-[20%]">
            <Spinner className="h-10"></Spinner>
          </div>
        ) : (
          <>
            <div>
              <div className="card w-full card pt-40 lg:pt-56">
                <div className="max-w-6xl mx-auto xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                  <h1 className="font-bold text-center font-sora md:text-[30px] text-[25px] lg:leading-[60px] leading-9 text-[#4A4A4A] capitalize">
                    Latest Blog
                  </h1>
                </div>
              </div>
            </div>
            <section className="py-6 sm:py-12 ">
              <div className="container p-6 space-y-8 max-w-6xl mx-auto xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                <div className="grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2 lg:grid-cols-4 ">
                  {blogs?.data?.map((blog: any) => {
                    return (
                      <BlogItem
                        key={blog.id}
                        id={blog.id}
                        title={blog.attributes.title}
                        date={blog.attributes.createdAt}
                        image={blog.attributes.image.data.attributes.url}
                        description={blog.attributes.description}></BlogItem>
                    );
                  })}
                </div>
              </div>
            </section>
          </>
        )}
      </Layout>{' '}
    </div>
  );
}
export default Blogs;
