export const CONSTANT = {
  HOME: 'Home',
  DONATE: 'Donate',
  MEMBERSHIP: 'Membership',
  PUBLICATION: 'Publication',
  EVENTS: 'Events',
  CALENDAR: 'Calendar',
  PRAY_TIME: 'Pray Time',
  ABOUT_US: 'About Us',
  SERVICES: 'Services',
  BLOG: 'Blog'
};

export const MERMBERSHIP_CONDATION_EN = [
  {
    id: 1,
    content:
      ' All members are required to obey the Afghan Canadian Community Charitable Foundation by laws, resolutions passed by the general meetings, and written policies of ACCCF.'
  },
  {
    id: 2,
    content:
      ' Only those who have been members of ACCCF for 3 or more full consecutive years with no outstanding membership fees are eligible to receive the services that are free for ACCCF members, including burial services.'
  },
  {
    id: 3,
    content:
      'If your membership is suspended or terminated, you are not entitled to receive any of the free or discounted services that are offered to members.'
  },
  {
    id: 4,
    content:
      ' Membership suspension: If your membership fee is not paid in full by the end of each year (before or on the renewal day), it is automatically suspended. Membership status can be restored if all membership dues are paid off within five years after suspension of the membership.'
  },
  {
    id: 5,
    content:
      ' ACCCF board of directors has the authority to suspend or expel any member for violating any provision of the ACCCF articles, by-laws or written policies, carrying out any conduct which may be detrimental to ACCCF or for any other reason that the board of directors considers to be reasonable.'
  },
  {
    id: 6,
    content:
      ' Your membership will automatically be renewed each year and your credit card will be charged according to the method of payment selected for the next payment due unless you notify ACCCF of your desire to cancel your membership.'
  },
  {
    id: 7,
    content:
      'I agree to all of the above terms and conditions and authorize ACCCF to charge my credit card or debit card, written above, for my ACCCF membership fee as per options selected above.'
  }
];

export const MERMBERSHIP_CONDATION_PR = [
  {
    id: 1,
    content:
      ' تمامی اعضا، موظف به تبعیت از اساس نامه، قطعنامه های مجمع عمومی و  پالیسیهای مکتوب بنیاد خیریه جامعه افغان های کانادا می باشند'
  },
  {
    id: 2,
    content:
      '  خدمات رایگان، ازجمله خدمت خاکسپاری تنها به آن دسته از اعضا تعلق  میگیرد که مدت سه سال متوالی یا بیشتر ازعضویت شان گذشته باشد و هم چنین حقالعضویت خود را به صورت کامل پرداخت کرده باشند. اگر  عضویت شما تعلیق و یا لغو شده باشد، هیچ گونه خدمات رایگان یا خدمات با  تخفیف مخصوص اعضا، به شما تعلق نخواهد گرفت'
  },
  {
    id: 3,
    content:
      ' تعلیق عضویت: اگر حقالعضویت ساالنه شما تا ختم همان سال )قبل یا در  روز تجدید عضویت( به صورت کامل پرداخت نشود، عضویت شما به طور  خودکار تعلیق میشود. عضویت شما در صورتی که همۀ بدهیهای شما پس  از تعلیق، تسویه گردد، در جایگاه قبلی ابقا میشود'
  },
  {
    id: 4,
    content:
      ' شورای اجراییه بنیاد خیریه جامعه افغان های کانادا صالحیت دارد عضویت  هرعضوی را به دلیل نقض هریک از اصول اساسنامه و یا پالیسیهای  مکتوب بنیاد خیریه جامعه افغان های کانادا یا به خاطر انجام هرگونه عمل  زیان آور به بنیاد خیریه و یا به هر دلیل معقول دیگری که شورای اجراییه  بنیاد خیریه جامعه افغان های کانادا تشخیص بدهد، تعلیق و یا لغو کند'
  },
  {
    id: 5,
    content:
      'عضویت شما به طور خودکار هرسال تمدید میگردد و حقالعضویت شما  مطابق با روش انتحابی شما، در تاریخ معین از کریدیت کارت شما برداشت  خواهد شد؛ در صورتی که نمیخواهید عضویت شما تمدید شود، باید قبل از  تاریخ تجدید، خود شما بنیاد خیریه جامعه افغان های کانادا را مطلع سازید'
  },
  {
    id: 6,
    content:
      'اینجانب، موافقتم را با تمامی مقررات و شرایط مندرج فوق اعالم می دارم و  به بن یاد خ یریه جامعه افغانهای کانادا اجازه میدهم تا مبلغ حقالعضویت من را از کردیدت کارت و یا د بت کارت فوق الذکر، مطابق با گزینه های معین  شده، برداشت نماید '
  }
];
