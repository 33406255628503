import { useQuery, gql } from '@apollo/client';

export const GET_PRAYER_TIMES = gql`
  query getPrayerTimes($prayMonthId: ID, $limit: Int) {
    prayerTimes(
      sort: "date:ASC"
      pagination: { limit: $limit }
      filters: { pray_month: { id: { eq: $prayMonthId } } }
    ) {
      data {
        id
        attributes {
          Maghrib
          Sunset
          zuhr
          sunrise
          fajr
          imsac
          date
        }
      }
    }
  }
`;

export const useGetPrayerTimes = (limit: number, id?: number) => {
  const { loading, error, data, refetch } = useQuery(GET_PRAYER_TIMES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      prayMonthId: id,
      limit: limit
    }
  });
  return {
    parayerTimes: data?.prayerTimes,
    loading,
    error,
    refetch
  };
};
