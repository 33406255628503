import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;
const httpLink = createHttpLink({
  uri: `${strapiUrl}/graphql`,
  // requied in order to send auth cookie
  credentials: 'include'
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  // if (graphQLErrors)
  //   graphQLErrors.forEach(({ message, locations, path }) =>
  //     console.log(
  //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
  //     ),
  //   );

  if (networkError) {
    // On network errors, go to the maintenance page
    console.log(`[Network error]: ${networkError}`);
    if (networkError.message.includes('503') && window.location.pathname !== '/maintenance') {
      window.location.href = '/maintenance';
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache()
});

export default client;
