// import { ExclamationCircleIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import PropTypes, { InferProps } from 'prop-types';
function TextAreaField({
  defaultValue,
  errors,
  placeholder,
  register,
  name,
  label,
  suffix,
  maxLength = 'none',
  registerOptions = {},
  disabled = false,
  className = undefined
}: InferProps<typeof TextAreaField.propTypes>) {
  return (
    <div>
      <label htmlFor={name || ''} className="block text-sm font-bold text-gray-700">
        {label}
      </label>
      <div className="mt-1 relative">
        <div className="block w-full">
          <textarea
            id={name}
            rows={8}
            {...register(name, registerOptions)}
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={disabled}
            maxLength={maxLength}
            className={
              className
                ? className
                : clsx(
                    errors?.message == null
                      ? 'focus:ring-primary-500 focus:border-primary-500 border-[#D9D7D7] text-[#666666] '
                      : ' pr-10 border-red text-red placeholder-red focus:outline-none focus:ring-red focus:border-red ',
                    'shadow-sm rounded-md font-nunito text-lg',
                    suffix ? 'w-1/2 mr-2 inline' : 'block w-full'
                  )
            }
          />
          {suffix && <span className="inline text-gray-500 text-sm">{suffix}</span>}
        </div>
        {errors?.message && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            {/* <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" /> */}
          </div>
        )}
      </div>
      {errors?.message && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors?.message}
        </p>
      )}
    </div>
  );
}

TextAreaField.propTypes = {
  defaultValue: PropTypes.string,
  errors: PropTypes.any,
  placeholder: PropTypes.string,
  register: () => void {},
  name: PropTypes.string,
  registerOptions: PropTypes.any,
  label: PropTypes.string,
  suffix: PropTypes.string,
  maxLength: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default TextAreaField;
