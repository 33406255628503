import Button from '../shared/Button';
import borderImage from '../../assets/images/hero-image-border.svg';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../static/data';
const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;

const Hero = ({ hero }: any) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible
  };

  return (
    <motion.article
      initial="hidden"
      animate="visible"
      exit={{ opacity: 0, transition: { duration: 1 } }}
      variants={{ visible: { transition: { staggerChildren: 0.3 } } }}>
      <div className="w-full bg-[#FAFAFF] pb-5 pt-20">
        <div className="max-w-7xl mx-auto lg:grid lg:grid-cols-2 flex flex-col-reverse w-full min-h-screen gap-x-32 lg:pt-20 xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
          <div className="h-full pb-10 lg:pb-0">
            <div className="xl:mt-28 lg:mt-20 mt-12 px-5">
              <motion.h1
                variants={{
                  hidden: { opacity: 0, y: -20 },
                  visible
                }}
                className="font-sora md:text-5xl sm:text-3xl text-2xl font-bold bg-clip-text text-transparent text-[#1B8901]">
                {hero?.attributes?.title}
              </motion.h1>
              <motion.p
                variants={itemVariants}
                className="mt-6 lg:text-xl md:text-base font-normal font-nunito ">
                {hero?.attributes?.description_en}
              </motion.p>
              <motion.p
                variants={itemVariants}
                className="mt-6 lg:text-xl md:text-base text-right font-normal font-IranSanse">
                {hero?.attributes?.description_dr}
              </motion.p>
              <div className=" flex-none sm:flex gap-x-5">
                <motion.div variants={itemVariants}>
                  <Link to={ROUTES.DONATE_ROUTE}>
                    <Button className="mt-10" secondary={true}>
                      Donate Now
                    </Button>
                  </Link>
                </motion.div>
                <motion.div variants={itemVariants}>
                  <Link to={ROUTES.EVENT_ROUTE}>
                    <Button className=" mt-5 sm:mt-10" secondary={true}>
                      Events
                    </Button>
                  </Link>
                </motion.div>
              </div>
            </div>
          </div>
          <div className="h-full lg:justify-end justify-center relative mt-20 lg:mt-0  px-5 ">
            <div className="xl:h-[450px] xl:w-[450px] lg:h-[430px] lg:w-[430px] w-full md:h-[450px] sm:h-[300px] h-[200px] xl:mt-16 lg:mt-32 relative ">
              <img className="absolute w-full z-20 h-full hidden lg:block" src={borderImage}></img>
              <motion.div
                initial={false}
                animate={
                  isLoaded && isInView
                    ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                    : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                }
                transition={{ duration: 1, delay: 1 }}
                viewport={{ once: true }}
                className="w-full h-full"
                onViewportEnter={() => setIsInView(true)}>
                <img
                  className="h-full w-full mp-8 object-cover rounded-lg lg:rounded-full p-0 lg:p-10"
                  src={hero?.attributes?.image?.data?.attributes?.url}
                  alt=""
                  onLoad={() => setIsLoaded(true)}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.article>
  );
};

export default Hero;
