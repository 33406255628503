import { useQuery, gql } from '@apollo/client';

export const GET_PRAY_MONTHS = gql`
  query getPraMonths($limit: Int) {
    prayMonths(pagination: { limit: $limit }) {
      data {
        id
        attributes {
          en_name
          fa_name
          isCurrentMonth
        }
      }
    }
  }
`;

export const useGetPrayMonths = (limit: number) => {
  const { loading, error, data } = useQuery(GET_PRAY_MONTHS, {
    fetchPolicy: 'cache-and-network',
    variables: { limit: limit }
  });
  return {
    prayMonths: data?.prayMonths,
    loading,
    error
  };
};
