import { useQuery, gql } from '@apollo/client';

export const GET_SERVICES = gql`
  query getServices {
    services {
      data {
        id
        attributes {
          title
          icon {
            data {
              attributes {
                url
              }
            }
          }
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetServices = () => {
  const { loading, error, data } = useQuery(GET_SERVICES, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    services: data?.services,
    loading,
    error
  };
};
