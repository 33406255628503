import Layout from '../../components/layout/layout';
import PublicationItem from '../../components/publication/publicationItem';
import Spinner from '../../components/shared/Spinner';
import { useGetPublications } from '../../hooks/publication/getPublications';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;

function Publication() {
  const { loading, publications, publicationSection } = useGetPublications();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);

  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Publication </title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>

        {!loading && (
          <motion.article
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 1 } }}
            variants={{ visible: { transition: { staggerChildren: 0.3 } } }}>
            <div className="w-full min-h-screen ">
              <div className="w-full bg-[#FAFAFF] pt-44 pb-10">
                <div className="max-w-7xl mx-auto flex flex-col-reverse lg:grid lg:grid-cols-2 gap-x-28 lx:px-0 lg:px-20 md:px-16 sm:px-12 px-5 mb-20">
                  <div className="mb-10 lg:mb-0 mt-0 lg:mt-10">
                    <motion.h1
                      variants={{
                        hidden: { opacity: 0, y: -20 },
                        visible
                      }}
                      className="font-bold font-sora lg:text-[30px] md:text-[25px] text-xl leading-[60px] py-2 text-[#4A4A4A] mt-5">
                      {publicationSection?.attributes?.title}
                    </motion.h1>
                    <motion.p
                      variants={itemVariants}
                      className="font-nunito mt-5  font-normal lg:text-2xl md:text-xl text-base lg:leading-[38px] leading-7 text-[#575757] tracking-[0.5%] ">
                      {publicationSection?.attributes?.description}
                    </motion.p>
                  </div>
                  <div className=" relative lg:mt-10">
                    <motion.div
                      initial={false}
                      animate={
                        isLoaded && isInView
                          ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                          : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                      }
                      transition={{ duration: 1, delay: 1 }}
                      viewport={{ once: true }}
                      className="w-full h-full rounded-[32px]"
                      onViewportEnter={() => setIsInView(true)}>
                      <img
                        className=" rounded-lg sm:rounded-[32px] h-full max-h-[300px] sm:max-h-[450px] w-full object-cover shadow-[0_22px_32px_rgba(63,135,247,0.16)] "
                        src={publicationSection?.attributes?.image?.data?.attributes?.url}
                        alt=""
                        onLoad={() => setIsLoaded(true)}
                      />
                      <div className=" absolute h-full rounded-[32px] top-0 w-full bg-gradient-to-r from-[#4085F7]/[0.27] to-[#C4C4C4]/[0]"></div>
                    </motion.div>
                  </div>
                </div>
              </div>
              <div className=" max-w-7xl mx-auto bg-white pb-28 pt-5 xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                <div className="w-full text-center md:text-4xl sm:text-3xl text-xl font-sora capitalize font-semibold mt-12 lg:leading-[70px] md:leading-[50px] sm:leading-[30px] leading-7 tracking-[2%] ">
                  <span className="text-[#565656]">Our</span>{' '}
                  <span className="text-[#1B8901]">Publications</span>
                </div>
                <div className="py-10 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 mt-8 lg:px-0 md:px-10 px-5">
                  {publications?.data?.map((publication: any, index: number) => {
                    return (
                      <motion.div
                        key={index}
                        initial={{ x: -200, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ duration: index }}>
                        <PublicationItem
                          title={publication?.attributes?.title}
                          file={
                            publication?.attributes?.file?.data?.attributes?.url
                          }></PublicationItem>
                      </motion.div>
                    );
                  })}
                </div>
              </div>
            </div>
          </motion.article>
        )}
        {loading && (
          <div className="w-full h-screen flex text-center justify-center pt-[20%]">
            <Spinner className="w-6 h-6"></Spinner>
          </div>
        )}
      </div>
    </Layout>
  );
}
export default Publication;
