/* eslint-disable prettier/prettier */
import Layout from '../../components/layout/layout';
import { Helmet } from 'react-helmet';
import Spinner from '../../components/shared/Spinner';
import Table from '../../components/shared/Table';
import React, { useEffect, useState } from 'react';
import { useGetPrayerTimes } from '../../hooks/prayer-time/useGetPrayerTimes';
import { useGetPrayMonths } from '../../hooks/prayer-time/useGetPrayMonths';
import { formatPrayTimeDate, formatTime } from '../../utill/formatDate';
import { DropdownModel } from '../../lib/model/DropdownModel';
import Dropdown from '../../components/shared/Dropdown';
function PrayTime() {
  const [selectedMonth, setSelectedMonth] = useState<DropdownModel>();

  const { loading, parayerTimes, refetch } = useGetPrayerTimes(-1);
  const { prayMonths } = useGetPrayMonths(-1);

  const columns = React.useMemo(
    () => [
      {
        Header: () => {
          return (
            <div>
              <div>تاریخ</div>
              <div>Date</div>
            </div>
          );
        },
        accessor: 'attributes.date',
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              {formatPrayTimeDate(cell.row.original.attributes.date)}
            </div>
          );
        }
      },
      {
        Header: () => {
          return (
            <div>
              <div>امساک</div>
              <div>Imsac</div>
            </div>
          );
        },
        accessor: 'attributes.imsac',
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              {formatTime(cell.row.original.attributes.imsac)}
            </div>
          );
        }
      },
      {
        Header: () => {
          return (
            <div>
              <div>آذان فجر</div>
              <div>Fajr</div>
            </div>
          );
        },
        accessor: 'attributes.fajr',
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              {formatTime(cell.row.original.attributes.fajr)}
            </div>
          );
        }
      },
      {
        Header: () => {
          return (
            <div>
              <div>طلوع آفتاب</div>
              <div>Sunrise</div>
            </div>
          );
        },
        accessor: 'attributes.sunrise',
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              {formatTime(cell.row.original.attributes.sunrise)}
            </div>
          );
        }
      },
      {
        Header: () => {
          return (
            <div>
              <div>آذان ظهر</div>
              <div>Zuhr</div>
            </div>
          );
        },
        accessor: 'attributes.zuhr',
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              {formatTime(cell.row.original.attributes.zuhr)}
            </div>
          );
        }
      },
      {
        Header: () => {
          return (
            <div>
              <div>غروب آفتاب</div>
              <div>Sunset</div>
            </div>
          );
        },
        accessor: 'attributes.Sunset',
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              {formatTime(cell.row.original.attributes.Sunset)}
            </div>
          );
        }
      },
      {
        Header: () => {
          return (
            <div>
              <div>آذان مغرب</div>
              <div>Maghrib</div>
            </div>
          );
        },
        accessor: 'attributes.Maghrib',
        Cell: ({ cell }: any) => {
          return (
            <div className="flex gap-x-4 justify-center">
              {formatTime(cell.row.original.attributes.Maghrib)}
            </div>
          );
        }
      }
    ],
    []
  );

  useEffect(() => {
    const currentMonth = prayMonths?.data?.find((month: any) => month.attributes.isCurrentMonth);
    if (currentMonth) {
      setSelectedMonth({
        title: `${currentMonth?.attributes.en_name} ${currentMonth?.attributes.fa_name}`,
        id: currentMonth.id
      });
      refetch({ prayMonthId: currentMonth.id });
    }
  }, [prayMonths?.data]);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        await refetch({ prayMonthId: id });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    const currentMonth = prayMonths?.data?.find((month: any) => month.attributes.isCurrentMonth);
    if (currentMonth) {
      setSelectedMonth({
        title: `${currentMonth?.attributes.en_name} ${currentMonth?.attributes.fa_name}`,
        id: currentMonth.id
      });
      fetchData(currentMonth.id);
    } else if (prayMonths?.data?.length > 0) {
      setSelectedMonth({
        title: `${prayMonths?.data[0]?.attributes.en_name} ${prayMonths?.data[0]?.attributes.fa_name}`,
        id: prayMonths?.data[0]?.id
      });
      fetchData(prayMonths?.data[0]?.id);
    }
  }, [prayMonths?.data]); // Empty array means this effect runs once on mount

  const onChangeItem = async (item: DropdownModel) => {
    if (item) {
      setSelectedMonth(item);
      await refetch({ prayMonthId: item.id });
    }
  };
  return (
    <Layout>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Pray Time</title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>
        {!loading && (
          <div className="flex justify-center pt-20">
            <div className="py-10 w-full space-y-6 max-w-7xl mx-auto">
              <div className="space-y-4">
                <h1 className="text-center font-IranSanse font-bold">اوقات نماز (شهر سسکتون)</h1>
                <div className="max-w-[200px] mx-auto items-center justify-center w-auto">
                  <Dropdown
                    placeholder="select month"
                    onChange={onChangeItem}
                    options={prayMonths?.data?.map((item: any) => {
                      return {
                        title: `${item?.attributes.en_name} ${item?.attributes.fa_name}`,
                        id: item.id
                      };
                    })}
                    defaultValue={selectedMonth}
                  />
                </div>
              </div>
              <Table columns={columns} data={parayerTimes?.data || []} />
            </div>
          </div>
        )}
        {loading && (
          <div className="w-full h-screen flex text-center justify-center pt-[20%]">
            <Spinner className="w-6 h-6"></Spinner>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default PrayTime;
