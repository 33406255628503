/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from '@apollo/client';
// import { GET_LOCATIONS } from './useGetLocations';

interface ContactInput {
  variables: {
    data: {
      email: string;
      name: string;
      subject: string;
      message: string;
    };
  };
}

export const CREATE_CONTACT = gql`
  mutation createContact($data: ContactInput!) {
    createContact(data: $data) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const GET_CONTACTS = gql`
  query getContact {
    contacts {
      data {
        attributes {
          name
          message
          subject
          email
        }
      }
    }
  }
`;

export const useCreateContact = (): ((createContact: ContactInput) => any) => {
  const [createContactResult] = useMutation(CREATE_CONTACT, {
    refetchQueries: [GET_CONTACTS, 'GetContact']
  });

  return createContactResult;
};
