import { useQuery, gql } from '@apollo/client';

export const GET_DONATE = gql`
  query getDonate {
    donateSection {
      data {
        attributes {
          title
          description
          image {
            data {
              attributes {
                url
              }
            }
          }
          cards {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetDonate = () => {
  const { loading, error, data } = useQuery(GET_DONATE, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    donate: data?.donateSection?.data,
    loading,
    error
  };
};
