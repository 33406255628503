import Layout from '../layout/layout';
import ContactUS from './ContactUs';
import Service from './service';
import AboutUs from './AboutUs';
import Hero from './Hero';
import Committee from './Committee';
import { useGetHomeSection } from '../../hooks/home/useGetHomeSection';
import Spinner from '../shared/Spinner';
import { Helmet } from 'react-helmet';
const Landing = () => {
  const { error, loading, hero, about } = useGetHomeSection();

  return (
    <div>
      <Layout>
        {!loading && (
          <div>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Afghan Canadian</title>
              <meta
                name="description"
                content="the best charity organzation for helping afghan people in canda"
              />
            </Helmet>
            <Hero hero={hero}></Hero>
            <Committee></Committee>
            <AboutUs about={about}></AboutUs>
            <Service></Service>
            <ContactUS></ContactUS>
          </div>
        )}
        {loading && (
          <div className="w-full h-screen flex text-center justify-center pt-[20%]">
            <Spinner className="w-6 h-6"></Spinner>
          </div>
        )}
      </Layout>
    </div>
  );
};

export default Landing;
