import { useQuery, gql } from '@apollo/client';

export const GET_COMMITTES = gql`
  query getCommittes {
    committes {
      data {
        id
        attributes {
          logo {
            data {
              attributes {
                url
              }
            }
          }
          link
          name
        }
      }
    }
  }
`;

export const useGetCommittes = () => {
  const { loading, error, data } = useQuery(GET_COMMITTES, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    committes: data?.committes,
    loading,
    error
  };
};
