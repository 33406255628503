import React from 'react';
import { DropdownModel } from '../../lib/model/DropdownModel';
type Props = {
  selected?: boolean;
  value: DropdownModel | undefined;
  [x: string]: any;
};

type LabelProps = {
  placeholder?: string;
  prefix?: any;
  value: DropdownModel | undefined;
  iconType?: 'none' | 'image' | 'icon' | 'onlineIndicatro';
  className?: string;
};

const InputDropdownOption: React.FC<Props> = (props) => {
  const { value, selected } = props;

  return (
    <div
      className={`flex justify-between h-full py-2.5 px-3.5 hover:bg-gray-100 ${
        selected ? ' bg-gray-100' : ' bg-white'
      } `}>
      <div className=" flex gap-x-2 text-md font-medium items-center justify-center">
        <h3 className="text-gray-900 whitespace-nowrap font-IranSanse">{value?.title}</h3>
      </div>
    </div>
  );
};

export default InputDropdownOption;

export const InputDropdownLabel: React.FC<LabelProps> = (props) => {
  const { value, iconType, placeholder, prefix, className } = props;

  return (
    <div className=" flex justify-between">
      <div className=" flex gap-x-2 text-md items-center justify-center">
        {((prefix && iconType === 'none') || (prefix && iconType !== 'none' && !value)) && prefix}
        {value ? (
          <h2
            className={`flex gap-x-2 text-center text-gray-500 hover:text-gray-700 font-IranSanse ${className}`}>
            {value?.title}
          </h2>
        ) : (
          <h3 className="text-gray-500 font-IranSanse text-center">{placeholder}</h3>
        )}
      </div>
    </div>
  );
};
