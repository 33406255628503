import { Link } from 'react-router-dom';
import { ROUTES } from '../../static/data';
import Button from '../shared/Button';
const AboutUs = ({ about }: any) => {
  return (
    <div className="w-full relative py-10 bg-[#FAFAFF]">
      <div className="max-w-7xl mx-auto md:py-20 py-2 xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
        <div className=" flex flex-col-reverse lg:grid lg:grid-cols-2 gap-x-10">
          <div className=" relative py-[20%] sm:pr-20">
            <h1 className="font-bold font-sora md:text-[30px] text-[25px] leading-[60px] py-2 text-[#4A4A4A] capitalize">
              {about?.attributes?.title}
            </h1>
            <div>
              <div className=" h-1 w-20 bg-[#1B8901] rounded-sm"></div>
            </div>
            <p className=" font-nunito mt-5 font-normal md:text-2xl text-lg leading-[38px] text-[#575757] tracking-[0.5%]">
              {about?.attributes?.description}
            </p>
            <div className="justify-items-center mt-10">
              <Link to={ROUTES.ABOUTUS_ROUTE}>
                <Button secondary={true}>Read More </Button>
              </Link>
            </div>
          </div>
          <div className="w-full h-full">
            <div className=" sm:grid sm:grid-cols-2 gap-x-5 w-full h-full">
              <div>
                <div className="sm:grid sm:grid-rows-5 sm:gap-y-5 sm:pt-10 h-full max-h-[500px]">
                  <img
                    className=" sm:row-span-3 h-full w-full rounded-xl sm:rounded-tr-[30px] sm:rounded-tl-[30px] sm:rounded-bl-[30px] sm:rounded-br-none object-cover shadow-[0_10px_40px_rgba(0,0,0,0.25)] sm:border-2 sm:border-white"
                    src={about?.attributes?.image1?.data?.attributes?.url}
                    alt=""
                  />
                  <div className="h-full sm:row-span-2 hidden sm:block sm:pl-10">
                    <img
                      className=" h-full w-full object-cover sm:rounded-tl-[30px] sm:rounded-bl-[30px] sm:rounded-br-[30px] shadow-[0_10px_40px_rgba(0,0,0,0.25)]"
                      src={about?.attributes?.image2?.data?.attributes?.url}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className=" hidden sm:block ">
                <div className="sm:grid sm:grid-rows-5 sm:gap-y-5 sm:pb-10 h-full max-h-[500px]">
                  <div className="sm:row-span-2 sm:pr-10">
                    <img
                      className="h-full w-full object-cover sm:rounded-tr-[30px] sm:rounded-tl-[30px] sm:rounded-br-[30px] shadow-[0_10px_40px_rgba(0,0,0,0.25)] "
                      src={about?.attributes?.image3?.data?.attributes?.url}
                      alt=""
                    />
                  </div>
                  <img
                    className="sm:row-span-3 h-full w-full object-cover sm:rounded-tr-[30px] sm:rounded-bl-[30px] sm:rounded-br-[30px] shadow-[0_10px_40px_rgba(0,0,0,0.25)] border-2 border-white"
                    src={about?.attributes?.image4?.data?.attributes?.url}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
