export function formatDate(dateStr: string) {
  const date = new Date(dateStr);
  const options: any = { month: 'short', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

export function formatPrayTimeDate(dateStr: string) {
  const options: any = {
    weekday: 'long', // Full weekday name (e.g., "Monday")
    month: 'long', // Full month name (e.g., "March")
    day: 'numeric' // Day of the month (e.g., "11")
    // year: 'numeric' // Year (e.g., "2024")
  };

  const formattedDate = new Date(dateStr).toLocaleDateString(undefined, options);
  return formattedDate;
}

export function formatTime(timeString: string) {
  const options: any = {
    hour: 'numeric', // 12-hour clock
    minute: 'numeric', // Minutes
    hour12: true // Use AM/PM
  };

  const formattedTime = new Date(`1970-01-01T${timeString}`).toLocaleTimeString(undefined, options);
  return formattedTime;
}
