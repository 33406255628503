import './header.css';
import { useState, useEffect } from 'react';
import { CONSTANT } from '../../static/constants';
import { ROUTES } from '../../static/data';
import { Disclosure } from '@headlessui/react';
import classNames from 'classnames';
import logo from '../../assets/images/logo.svg';
import line from '../../assets/images/line.svg';
import xmark from '../../assets/images/xmark.svg';
const Header = ({ meta }: any) => {
  const navigation = [
    { id: 1, title: CONSTANT.HOME, to: ROUTES.HOME_ROUTE },
    { id: 2, title: CONSTANT.DONATE, to: ROUTES.DONATE_ROUTE },
    { id: 3, title: CONSTANT.MEMBERSHIP, to: ROUTES.MEMBERSHIP_ROUTE },
    { id: 4, title: CONSTANT.PUBLICATION, to: ROUTES.PUBLICATION_ROUTE },
    { id: 5, title: CONSTANT.EVENTS, to: ROUTES.EVENT_ROUTE },
    { id: 6, title: CONSTANT.CALENDAR, to: ROUTES.CALENDAR_ROUTE },
    { id: 7, title: CONSTANT.PRAY_TIME, to: ROUTES.PRAY_TIME_ROUTE },
    { id: 8, title: CONSTANT.ABOUT_US, to: ROUTES.ABOUTUS_ROUTE },
    { id: 9, title: CONSTANT.BLOG, to: ROUTES.BLOGS_ROUTE }
  ];
  const [activeLink, setActiveLink] = useState(navigation[0]?.title);
  const [scrollActive, setScrollActive] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrollActive(window.scrollY > 20);
    });
  }, []);

  useEffect(() => {
    setActiveLink(window?.location?.pathname);
  }, []);

  return (
    <>
      {/* <header
        className={
          'fixed top-0 w-full  z-30  transition-all ' +
          (scrollActive ? ' shadow-md pt-0 bg-white' : ' pt-4')
        }>
        <div className="w-full mb-0 lg:mb-5 max-w-7xl mx-auto md:px-16 sm:px-12 px-5 ">
          <div className=" max-w-6xl mx-auto flex justify-between">
            <div className="col-start-1 col-end-2 flex items-center">
              <div className="h-20 w-auto outline-none">
                <Link
                  className=" outline-none"
                  onClick={() => setActiveLink(ROUTES.HOME_ROUTE)}
                  to={ROUTES.HOME_ROUTE}>
                  <img src={Logo} className="h-full"></img>
                </Link>
              </div>
            </div>
            <div className=" flex flex-col gap-y-2">
              <div className="text-sm mt-3 w-32 py-2 text-white text-center rounded-lg bg-[#1B8901] hover:text-[#E2050E] font-bold capitalize cursor-pointer tracking-wide  transition-all">
                <a
                  onClick={() => setActiveLink('Sign In')}
                  target="_blank"
                  href="https://acccf.wildapricot.org/Sys/Login"
                  rel="noreferrer">
                  Sign In
                </a>
              </div>
              <div className="w-32 text-sm border-2 border-[#1B8901] text-center py-2 rounded-lg text-[#1B8901] hover:text-[#E2050E] font-bold cursor-pointer">
                <a
                  onClick={() => setActiveLink(ROUTES.MEMBERSHIP_ROUTE)}
                  href={ROUTES.MEMBERSHIP_ROUTE}>
                  Sign Up
                </a>
              </div>
            </div>
          </div>
        </div>
        <nav className="max-w-screen-xl px-6 sm:px-8 lg:px-16 mx-auto grid grid-flow-col py-2">
          <ul className="hidden lg:flex gap-x-10 text-black-500  items-center">
            {headers?.map((header) => {
              return (
                <Link
                  onClick={() => setActiveLink(header.title)}
                  className={
                    'px-4 py-2 text-sm hover:text-[#1B8901] font-bold mx-2 cursor-pointer animation-hover inline-block relative' +
                    (activeLink == header.to
                      ? ' text-[#1B8901] animation-active'
                      : ' text-[#575757]')
                  }
                  to={header.to}
                  key={header.id}>
                  {header.title}
                </Link>
              );
            })}
          </ul>
        </nav>
      </header>
      Mobile Navigation
      <nav className="fixed lg:hidden bottom-0 left-0 right-0 z-40 bg-white px-4 sm:px-8 shadow-t ">
        <div className="bg-white-500 sm:px-3">
          <ul className="flex w-full justify-center text-black-500">
            {headers?.map((header) => {
              return (
                <a
                  onClick={() => {
                    setActiveLink(header.title);
                  }}
                  className={
                    'mx-1 sm:mx-2 px-3 sm:px-4 py-2 hover:text-[#1B8901] flex flex-col cursor-pointer text-xs border-t-2 transition-all ' +
                    (activeLink === header?.to
                      ? ' text-[#1B8901] '
                      : ' border-transparent text-[#575757]')
                  }
                  href={header.to}
                  key={header.id}>
                  {header.title}
                </a>
              );
            })}
          </ul>
        </div>
      </nav>
      End Mobile Navigation */}
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div
              className={
                'fixed top-0 w-full  z-30  transition-all ' +
                (scrollActive ? ' shadow-md pt-0 bg-white' : ' pt-4')
              }>
              <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                  <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                    {/* Mobile menu button*/}
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      <img className="h-6 w-6 " src={open ? xmark : line} />
                    </Disclosure.Button>
                  </div>
                  <div className="flex flex-1 items-center justify-center md:items-stretch md:justify-start">
                    <div className="flex flex-shrink-0 items-center">
                      <a href={ROUTES.HOME_ROUTE}>
                        <img className="h-12 w-auto" src={logo} alt="Your Company" />
                      </a>
                    </div>
                    <div className="hidden md:ml-6 md:flex md:items-center">
                      <div className="flex items-center space-x-4">
                        {navigation.map((item) => (
                          <a
                            key={item.id}
                            href={item.to}
                            className={classNames(
                              item.to === activeLink
                                ? ' text-[#1B8901]'
                                : 'text-gray-800  hover:text-[#1B8901]',
                              'rounded-md h-full px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={item.to === activeLink ? 'page' : undefined}>
                            {item.title}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0">
                    {/* Signup/Login */}
                    <div className="flex gap-x-2">
                      <div className="text-sm flex items-center justify-center w-20 h-10 text-white text-center rounded-lg bg-[#1B8901] hover:text-[#E2050E] font-bold capitalize cursor-pointer tracking-wide  transition-all">
                        <a
                          onClick={() => setActiveLink('Sign In')}
                          target="_blank"
                          href="https://acccf.wildapricot.org/Sys/Login"
                          rel="noreferrer">
                          Sign In
                        </a>
                      </div>
                      <div className="w-20 text-sm border-2 h-10 flex items-center justify-center border-[#1B8901] text-center py-2 rounded-lg text-[#1B8901] hover:text-[#E2050E] font-bold cursor-pointer">
                        <a
                          onClick={() => setActiveLink(ROUTES.MEMBERSHIP_ROUTE)}
                          href={ROUTES.MEMBERSHIP_ROUTE}>
                          Sign Up
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-16">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.id}
                    as="a"
                    href={item.to}
                    className={classNames(
                      item.to === activeLink
                        ? 'bg-gray-900 text-[#1B8901]'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-[#1B8901]',
                      'block rounded-md px-6 py-2 text-base font-medium'
                    )}
                    aria-current={item.to === activeLink ? 'page' : undefined}>
                    {item.title}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Header;
