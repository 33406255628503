import Layout from '../../components/layout/layout';
import { useGetAboutUs } from '../../hooks/about-us/useGetAbout';
import { ROUTES } from '../../static/data';
import Button from '../../components/shared/Button';
import Spinner from '../../components/shared/Spinner';
import { Variants } from 'framer-motion';
import sanitizedData from '../../utill/getSanitize';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function AboutUs() {
  const { loading, about, board } = useGetAboutUs();
  const cardVariants: Variants = {
    offscreen: {
      y: 300
    },
    onscreen: {
      y: 0,
      transition: {
        type: 'spring',
        bounce: 0.4,
        duration: 0.8
      }
    }
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Afghan Candian</title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>

        {!loading && (
          <div>
            <div className="card w-full card bg-[#FAFAFF] pb-36 pt-40 lg:pt-56">
              <div className="max-w-6xl mx-auto xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                <h1 className="font-bold text-center font-sora md:text-[30px] text-[25px] lg:leading-[60px] leading-9 text-[#4A4A4A] capitalize">
                  Our History
                </h1>
                <div>
                  <div className=" h-1 w-20 mx-auto bg-[#1B8901] rounded-sm"></div>
                </div>
                <div
                  className="mt-10 "
                  dangerouslySetInnerHTML={sanitizedData(about?.data?.attributes?.history)}></div>
              </div>
            </div>

            <div className="w-full card bg-white py-20">
              <div className="max-w-6xl mx-auto xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                <h1 className="font-bold font-sora md:text-[30px] text-center text-[25px] lg:leading-[60px] leading-9 text-[#4A4A4A] capitalize">
                  Our Vision
                </h1>
                <div>
                  <div className=" h-1 w-20 mx-auto bg-[#1B8901] rounded-sm"></div>
                </div>
                <div
                  className=" mt-10"
                  dangerouslySetInnerHTML={sanitizedData(about?.data?.attributes?.vision)}></div>
              </div>
            </div>

            <div className="w-full card bg-[#FAFAFF] py-20 ">
              <div className="max-w-6xl mx-auto xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                <h1 className="font-bold font-sora text-center md:text-[30px] text-[25px] lg:leading-[60px] leading-9 text-[#4A4A4A] capitalize">
                  Our Mission
                </h1>
                <div>
                  <div className=" h-1 w-20 bg-[#1B8901] mx-auto rounded-sm"></div>
                </div>
                <div
                  className=" mt-10"
                  dangerouslySetInnerHTML={sanitizedData(about?.data?.attributes?.mission)}></div>
              </div>
            </div>

            <div className="w-full card bg-white py-20 ">
              <div className="max-w-6xl mx-auto xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                <h1 className="font-bold font-sora text-center md:text-[30px] text-[25px] lg:leading-[60px] leading-9 text-[#4A4A4A] capitalize">
                  Our Project
                </h1>
                <div>
                  <div className=" h-1 w-20 bg-[#1B8901] mx-auto rounded-sm"></div>
                </div>
                <div
                  className=" mt-10"
                  dangerouslySetInnerHTML={sanitizedData(about?.data?.attributes?.project)}></div>
                <div className=" flex justify-center">
                  <a href={ROUTES.DONATE_ROUTE}>
                    <Button className="mt-10 mx-auto" secondary={true}>
                      Donate Now
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            <div className="w-full card bg-[#FAFAFF] py-20">
              <div className="max-w-6xl mx-auto xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5">
                <h1 className="font-bold font-sora text-center md:text-[30px] text-[25px] lg:leading-[60px] leading-9 text-[#4A4A4A] capitalize">
                  Our Board
                </h1>
                <div>
                  <div className=" h-1 w-20 bg-[#1B8901] mx-auto rounded-sm"></div>
                </div>
                <div
                  className=" mt-10"
                  dangerouslySetInnerHTML={sanitizedData(board?.data?.attributes?.content)}></div>
                <div className=" flex justify-center">
                  <a href={ROUTES.BORAD_DIRECTORY_ROUTE}>
                    <Button className="mt-10 mx-auto" secondary={true}>
                      Board Members
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        {loading && (
          <div className="w-full h-screen flex justify-center text-center pt-[29%]">
            <Spinner className="h-7"></Spinner>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default AboutUs;
