import { useQuery, gql } from '@apollo/client';

export const GET_BOARD = gql`
  query getBoard($endDate: Date) {
    board {
      data {
        attributes {
          title
          content
          borad_members(pagination: { start: 0, limit: -1 }) {
            data {
              attributes {
                name
                description
                image {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                board_positions(
                  filters: { or: [{ endDate: { gte: $endDate } }, { endDate: { eq: null } }] }
                ) {
                  data {
                    attributes {
                      startDate
                      endDate
                      position {
                        data {
                          attributes {
                            title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    positions(sort: "rank:asc", pagination: { start: 0, limit: -1 }) {
      data {
        attributes {
          title
          board_positions(sort: ["startDate:DESC"]) {
            data {
              attributes {
                startDate
                endDate
                borad_member {
                  data {
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetBoard = (endDate: string) => {
  const { loading, error, data } = useQuery(GET_BOARD, {
    fetchPolicy: 'cache-and-network',
    variables: {
      endDate: endDate
    }
  });
  return {
    board: data?.board,
    positions: data?.positions,
    loading,
    error
  };
};
