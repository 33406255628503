import { motion } from 'framer-motion';
import { useState } from 'react';
import Iframe from 'react-iframe';
import Layout from '../../components/layout/layout';
import Spinner from '../../components/shared/Spinner';
import { useGetDonate } from '../../hooks/donate/useGetDonate';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
const hiddenMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 30px, rgba(0,0,0,1) 30px, rgba(0,0,0,1) 30px)`;
const visibleMask = `repeating-linear-gradient(to right, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 0px, rgba(0,0,0,1) 0px, rgba(0,0,0,1) 30px)`;
function Donate() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);
  const { donate, error, loading } = useGetDonate();
  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Donation</title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>

        {!loading && (
          <motion.article
            initial="hidden"
            animate="visible"
            exit={{ opacity: 0, transition: { duration: 1 } }}
            variants={{ visible: { transition: { staggerChildren: 0.3 } } }}>
            <div className=" min-h-screen w-full">
              <div className="w-full bg-[#FAFAFF] min-h-screen pt-24 lg:pt-48 pb-20 lg:px-20 md:px-16 sm:px-12 px-5">
                <div className="max-w-7xl mx-auto lg:grid lg:grid-cols-2 gap-x-28 mt-20">
                  <div className=" relative">
                    <motion.div
                      initial={false}
                      animate={
                        isLoaded && isInView
                          ? { WebkitMaskImage: visibleMask, maskImage: visibleMask }
                          : { WebkitMaskImage: hiddenMask, maskImage: hiddenMask }
                      }
                      transition={{ duration: 1, delay: 1 }}
                      viewport={{ once: true }}
                      className="w-full h-full rounded-lg md:rounded-[32px]"
                      onViewportEnter={() => setIsInView(true)}>
                      <img
                        className=" rounded-lg md:rounded-[32px] h-full max-h-[450px] object-cover "
                        src={donate?.attributes?.image?.data?.attributes?.url}
                        alt=""
                        onLoad={() => setIsLoaded(true)}
                      />
                      {/* <div className=" absolute h-full rounded-[32px] top-0 w-full bg-gradient-to-r from-[#4085F7]/[0.27] to-[#C4C4C4]/[0]"></div> */}
                    </motion.div>
                  </div>
                  <div>
                    <motion.h1
                      variants={{
                        hidden: { opacity: 0, y: -20 },
                        visible
                      }}
                      className="font-bold font-sora lg:text-[30px] md:text-[25px] text-[20px] lg:leading-[60px] md:leading-[40px] leading-7 py-2 text-[#4A4A4A] mt-5 text-4xl">
                      {donate?.attributes?.title}
                    </motion.h1>
                    <motion.p
                      variants={itemVariants}
                      className="font-nunito mt-5  font-normal lg:text-2xl md:text-xl text-base lg:leading-[38px] leading-7 text-[#575757] tracking-[0.5%] ">
                      {donate?.attributes?.description}
                    </motion.p>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className=" max-w-7xl mx-auto lg:px-0 md:px-10 px-5">
                  <div className="wild-appricot">
                    <Iframe
                      url=""
                      className="w-full h-[1100px] relative mt-12 font-sora"
                      src="https://acccf.wildapricot.org/widget/Donate"
                      id=""
                      frameBorder={0}
                      display="block"
                      position="relative"
                    />
                  </div>
                </div>
              </div>
              <div className=" max-w-7xl lg:px-20 md:px-16 sm:px-12 px-5 py-12">
                <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
                  {donate.attributes.cards.data.map((card: any, index: number) => {
                    return (
                      <div className="h-48" key={index}>
                        <img
                          className="h-full object-cover rounded-xl w-full group bg-gradient-to-b p-[2px] from-[#FAFAFA] hover:from-white hover:cursor-pointer hover:shadow-[0_37px_75px_rgba(0,0,0,0.07)] to-transparent"
                          src={card.attributes.url}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </motion.article>
        )}
        {loading && (
          <div className="w-full h-screen flex text-center justify-center pt-[20%]">
            <Spinner className="w-6 h-6"></Spinner>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Donate;
