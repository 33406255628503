import { NavLink, useLocation } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';

function CustomNavLink({ title, to }: InferProps<typeof CustomNavLink.propTypes>) {
  const location = useLocation();
  return (
    <NavLink key={title} to={to || '/'} className="text-[#AFAFAF capitalize pt-2 pb-1">
      {/* {location.pathname === to ? modifiedIcons(activeIcon) : modifiedIcons(icon)} */}
      <span className="tab tab-home font-normal text-lg leading-7 block  text-left">{title}</span>
    </NavLink>
  );
}

CustomNavLink.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string
};

export default CustomNavLink;
