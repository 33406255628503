import Layout from '../../components/layout/layout';
import Spinner from '../../components/shared/Spinner';
import { useGetBoard } from '../../hooks/about-us/useGetBoards';
import sanitizedData from '../../utill/getSanitize';
import BoardMemberItem from '../../components/about-us/BoardMemberItem';
import BoardHistoryItem from '../../components/about-us/BoardHistoryItem';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

function BoardOfDirectory() {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = today.getFullYear();
  const endDate = yyyy + '-' + mm + '-' + dd;
  const { loading, positions, board } = useGetBoard(endDate);

  return (
    <Layout>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Board of Directory</title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>

        {loading ? (
          <div className="h-screen w-full flex justify-center pt-[20%]">
            <Spinner className="h-10"></Spinner>
          </div>
        ) : (
          <>
            <div className="bg-white w-full pb-20 pt-20 lg:pt-48">
              <div className=" max-w-6xl mx-auto">
                <div className="text-[#575757] mt-16 text-center text-3xl font-bold">
                  {board?.data?.attributes?.title}
                </div>
                <div className="px-5">
                  <div
                    dangerouslySetInnerHTML={sanitizedData(board?.data?.attributes?.content)}></div>
                </div>
                <div className="py-10 grid md:grid-cols-2 grid-cols-1 gap-5 mt-8 lg:px-0 md:px-10 px-5">
                  {board &&
                    board?.data?.attributes?.borad_members?.data
                      ?.filter(
                        (member: any) =>
                          member?.attributes?.board_positions?.data[0]?.attributes?.position?.data
                            ?.attributes?.title
                      )
                      ?.map((member: any, index: number) => {
                        return (
                          <BoardMemberItem
                            imgUrl={member?.attributes?.image?.data?.attributes?.url}
                            name={member?.attributes?.name}
                            title={
                              member?.attributes?.board_positions?.data[0]?.attributes?.position
                                ?.data?.attributes?.title
                            }
                            description={member?.attributes?.description}
                            key={index}></BoardMemberItem>
                        );
                      })}
                </div>
              </div>
            </div>
            <div className=" w-full py-20">
              <div className=" bg-white text-[#575757] text-center text-3xl font-bold">
                Former Board of Directors
              </div>
              <div className="max-w-6xl mx-auto mt-10">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[2px] bg-[#F5F7FB]">
                  {positions &&
                    positions?.data?.map((position: any, index: number) => {
                      return <BoardHistoryItem position={position} key={index}></BoardHistoryItem>;
                    })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}
export default BoardOfDirectory;
