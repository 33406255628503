import React from 'react';
import Spinner from './Spinner';

const baseClassName =
  'w-auto font-nunito font-bold md:text-xl text-lg inline-flex justify-center items-center rounded-[100px] text-center  py-3 px-6 text-white align-middle font-semibold';
const primaryClassName =
  'bg-[#E4060F] shadow-[0_8px_23px_rgba(65,132,247,0.24)] hover:cursor-pointer border  ';
const secondaryClassName =
  'bg-[#E4060F] hover:bg-[#93050F] hover:bg-gray-200 shadow-[0_8px_23px_rgba(65,132,247,0.24)] text-gray-700 border';

type Props = {
  primary?: boolean;
  primaryBlack?: boolean;
  secondary?: boolean;
  push?: boolean;
  loading?: boolean;
  // All other props
  type?: any;
  className?: string;
  [x: string]: any;
};

const Button: React.FC<Props> = (props) => {
  const { secondary, loading, className = '', type = 'button', children, ...rest } = props;
  let classes = baseClassName;
  if (secondary) {
    classes += ` ${secondaryClassName}`;
  } else {
    classes += ` ${primaryClassName}`;
  }

  classes += ` ${className}`;
  return (
    <button type={type || 'button'} className={classes} {...rest}>
      {loading ? <Spinner className="w-5 h-5 " /> : children}
      {/* { children } */}
    </button>
  );
};

export default Button;
