import { useQuery, gql } from '@apollo/client';

export const GET_CALENDARS = gql`
  query getCalendars($limit: Int) {
    calanders(sort: "month:ASC", pagination: { limit: $limit }) {
      data {
        id
        attributes {
          title
          month
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetCalendars = (limit: number) => {
  const { loading, error, data } = useQuery(GET_CALENDARS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      limit: limit
    }
  });
  return {
    calanders: data?.calanders,
    loading,
    error
  };
};
