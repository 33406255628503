import { useQuery, gql } from '@apollo/client';

export const GET_ABOUT = gql`
  query getAbout {
    about {
      data {
        id
        attributes {
          vision
          mission
          history
          project
        }
      }
    }
    board {
      data {
        id
        attributes {
          content
        }
      }
    }
  }
`;

export const useGetAboutUs = () => {
  const { loading, error, data } = useQuery(GET_ABOUT, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    about: data?.about,
    board: data?.board,
    loading,
    error
  };
};
