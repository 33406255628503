import { useQuery, gql } from '@apollo/client';

export const GET_FOOTER = gql`
  query getFooter {
    footer {
      data {
        attributes {
          address
          phone
          email
          facebookLink
          youtubeLink
          whatsappLink
        }
      }
    }
  }
`;

export const useGetFooter = () => {
  const { loading, error, data } = useQuery(GET_FOOTER, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    footer: data?.footer?.data,
    footerLoading: loading,
    error
  };
};
