import { useQuery, gql } from '@apollo/client';

export const GET_BLOG = gql`
  query getBlog($id: ID!) {
    blog(id: $id) {
      data {
        id
        attributes {
          title
          description
          updatedAt
          content
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetblog = (id: number) => {
  const { loading, error, data } = useQuery(GET_BLOG, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: id
    }
  });
  return {
    blog: data?.blog,
    loading,
    error
  };
};
