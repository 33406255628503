import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../static/data';
import { useGetServices } from '../../hooks/service/useGetServices';
const Service = () => {
  const { error, loading, services } = useGetServices();
  return (
    <div className="w-full max-w-7xl mx-auto">
      <div className="w-full text-center md:text-4xl sm:text-3xl text-xl font-sora capitalize font-semibold mt-12 lg:leading-[70px] md:leading-[50px] sm:leading-[30px] leading-7 tracking-[2%] xl:px-0 lg:px-20 md:px-16 sm:px-12 px-5 ">
        <span className="text-[#565656]">Our</span> <span className="text-[#1B8901]">Services</span>
      </div>
      <div className="py-10 grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-5 mt-8 lg:px-0 md:px-10 px-5">
        {!loading &&
          services?.data?.map((service: any) => {
            return (
              <div
                key={service.id}
                className="rounded-xl w-full group bg-gradient-to-b p-[2px] from-[#FAFAFA] hover:from-white hover:cursor-pointer hover:shadow-[0_37px_75px_rgba(0,0,0,0.07)] to-transparent">
                <Link to={`${ROUTES.SERVICES_ROUTE}/${service?.id}`}>
                  <div className="w-full h-wfull bg-white rounded-lg  py-10">
                    <div className="rounded-2xl w-20 h-20 mx-auto p-5 bg-[#1B8901]/[0.2] text-center">
                      <img
                        className="mt-[6px] object-contain text-[#1B8901] w-10 h-10 m-auto"
                        src={service?.attributes?.icon?.data?.attributes?.url}></img>
                    </div>
                    <div className=" font-sora font-semibold text-[#4A4A4A] text-2xl leading-9 text-center mt-5">
                      {service?.attributes?.title}
                    </div>
                    <div className="text-center font-poppins mt-4 flex justify-center gap-x-1 text-[#575757] group-hover:text-[#1B8901]">
                      <span className=" text-xl">Read More</span>
                      <ChevronRightIcon className="h-5 mt-[6px]"></ChevronRightIcon>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Service;
