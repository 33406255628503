import { useQuery, gql } from '@apollo/client';

export const GET_EVENT = gql`
  query getEvent($currentDate: Date!) {
    events(filters: { endDate: { gte: $currentDate }, and: { startDate: { lte: $currentDate } } }) {
      data {
        id
        attributes {
          title
          startDate
          endDate
          content
        }
      }
    }
  }
`;

export const useGetEvent = (currentDate: string) => {
  const { loading, error, data } = useQuery(GET_EVENT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      currentDate: currentDate
    }
  });
  return {
    event: data?.events?.data,
    loading,
    error
  };
};
