import Layout from '../../components/layout/layout';
import Spinner from '../../components/shared/Spinner';
import { useGetblog } from '../../hooks/blogs/useGetBlog';
import { useParams } from 'react-router-dom';
import sanitizedData from '../../utill/getSanitize';
import { Helmet } from 'react-helmet';
import { useGetBlogs } from '../../hooks/blogs/useGetBlogs';
import BlogItem from '../../components/blogs/blogItem';
function BlogDetails() {
  const { id } = useParams();
  const { loading, blog } = useGetblog(Number(id));
  const { error, loading: blogLoading, blogs } = useGetBlogs(5);

  return (
    <Layout>
      <div className="w-full min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{blog?.data?.attributes?.title || ''}</title>
          <meta name="description" content="you can save life of more afghans with your donate " />
        </Helmet>
        <div className=" w-full bg-[#FAFAFF]  py-28">
          {loading ? (
            <div className="h-screen w-full text-center">
              <Spinner className="h-10"></Spinner>
            </div>
          ) : (
            <div className="max-w-6xl mx-auto">
              <div className="w-full grid grid-cols-1 sm:grid-cols-6  md:grid-cols-7 gap-x-5 mt-12 lg:mt-28 px-5 sm:px-10 md:px-5 ">
                <div className="col-span-1 sm:col-span-4 md:col-span-5">
                  <div className="w-full text-center text-5xl font-sora  capitalize font-semibold  leading-[70px] tracking-[2%] ">
                    <h1 className="font-bold font-sora text-[40px] leading-[60px] py-2 text-[#4A4A4A] capitalize">
                      {blog?.data?.attributes?.title}
                    </h1>
                    <div className="flex justify-center">
                      <div className=" h-1 w-20 bg-[#FED369] rounded-sm"></div>
                    </div>
                    <div className="flex justify-center my-10">
                      {blog?.data?.attributes?.image && (
                        <div className=" relative w-full h-[450px]">
                          <img
                            className=" rounded-[32px] h-full w-full object-cover shadow-[0_22px_32px_rgba(63,135,247,0.16)] "
                            src={blog?.data?.attributes?.image?.data?.attributes?.url}
                            alt="no image"
                          />
                          <div className=" absolute h-full rounded-[32px] top-0 w-full bg-gradient-to-r from-[#4085F7]/[0.27] to-[#C4C4C4]/[0]"></div>
                        </div>
                      )}
                    </div>
                    <div>
                      <p
                        className="font-nunito mt-5  font-normal text-2xl leading-[38px] text-[#575757] tracking-[0.5%] text-justify"
                        dangerouslySetInnerHTML={sanitizedData(
                          blog?.data?.attributes?.content
                        )}></p>
                    </div>
                  </div>
                </div>
                <div className="col-span-1 sm:col-span-2 md:col-span-2">
                  <h1 className="font-bold font-sora text-[30px] leading-[60px] py-2 text-[#4A4A4A] capitalize">
                    Latest Blogs
                  </h1>
                  <div className="flex justify-left mb-10">
                    <div className=" h-1 w-20 bg-[#FED369] rounded-sm"></div>
                  </div>
                  <div className="flex flex-col gap-y-10">
                    {blogs?.data?.map((blog: any) => {
                      return (
                        <BlogItem
                          key={blog.id}
                          id={blog.id}
                          title={blog.attributes.title}
                          date={blog.attributes.createdAt}
                          image={blog.attributes.image.data.attributes.url}
                          description={blog.attributes.description}></BlogItem>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}
export default BlogDetails;
